<template>
  <div>
    <v-breadcrumbs
      v-if="oldVariant.product"
      class="pt-3 pl-7"
      :items="[{ text: 'Products', exact: true,  to: { name: 'products' } }, { text: oldVariant.product.name, exact: true, to: { name: 'product', params: { id: oldVariant.product_id }}}, { text: oldVariant.description }]"
    >
    </v-breadcrumbs>
    <v-row
      class="mt-0"
    >
      <v-col
        cols="12"
        md="6"
      >

        <v-card
          flat
          class="mx-3 pb-9"
        >
          <v-toolbar
            dark
            class="tertiary"
            dense
            elevation="0"
          >
            <v-toolbar-title>Variant</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="showVariantDialog()"
              :disabled="!user.can('product-variant.update')"
            >
              <v-icon color="secondary" small>fas fa-edit</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>

            <dl class="row table mt-5">
              <dt class="col-5">Code:</dt>
              <dd class="col-7">{{ oldVariant.unique_identifier }}</dd>
              <dt class="col-5">Description:</dt>
              <dd class="col-7">{{ oldVariant.description }}</dd>
              <dt class="col-5 pt-3">Weight:</dt>
              <dd class="col-7 pt-3">{{ oldVariant.weight }} kg</dd>
              <template v-if="user.can('show:sensitive')">
                <dt class="col-5">Sales Price:</dt>
                <dd class="col-7">{{ formatCurrency(oldVariant.sales_price) }}</dd>
              </template>
              <dt class="col-5 py-3">Size:</dt>
              <dd class="col-7 py-3"><template v-if="oldVariant.size">{{ oldVariant.size.name }}</template></dd>
              <dt class="col-5">Primary Color:</dt>
              <dd class="col-7"><template v-if="oldVariant.primary_color">{{ oldVariant.primary_color.name }}</template></dd>
              <dt class="col-5">Secondary Color:</dt>
              <dd class="col-7"><template v-if="oldVariant.secondary_color">{{ oldVariant.secondary_color.name }}</template></dd>
              <dt class="col-5">Tertiary Color:</dt>
              <dd class="col-7"><template v-if="oldVariant.tertiary_color">{{ oldVariant.tertiary_color.name }}</template></dd>
              <dt class="col-5">Quaternary Color:</dt>
              <dd class="col-7"><template v-if="oldVariant.quaternary_color">{{ oldVariant.quaternary_color.name }}</template></dd>
              <dt class="col-5 pt-3">Active:</dt>
              <dd class="col-7 pt-3"><v-icon :color="oldVariant.is_active ? 'secondary' : 'grey'" x-small>fas fa-circle</v-icon></dd>
            </dl>

          </v-card-text>

          <product-variant-dialog
            v-model="variantDialog"
            :attributes="variant"
            :errors="variantErrors"
            :sizes="sizes"
            :colors="colors"
            @save="saveVariant()"
            @delete="deleteVariant()"
          ></product-variant-dialog>


        </v-card>


      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-card
          flat
          class="mx-3 pb-9"
        >

          <v-toolbar
            dark
            class="tertiary"
            dense
            elevation="0"
          >
            <v-toolbar-title>Suppliers</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              dense
              background-color="primary"
              v-model="filter.query"
              label="Search..."
              single-line
              hide-details
              solo
              @keyup="variantSupplierSearch()"
            ></v-text-field>
            <v-btn
              icon
              color="secondary"
              @click="showNewVariantSupplierDialog()"
              :disabled="!user.can('product-variant-supplier.create')"
            >
              <v-icon small>fas fa-plus</v-icon>
            </v-btn>
          </v-toolbar>

          <v-data-table
            :class="{ 'mt-5': true, 'clickable': user.can('product-variant-supplier.update') }"
            :headers="variantSupplierHeaders"
            :items="variantSuppliers"
            :loading="isSearching"
            :options.sync="options"
            :server-items-length="totalCount"
            :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
            @click:row="showVariantSupplierDialog"
          >

            <template v-slot:item.purchase_price="{ item }">
              {{ formatCurrency(item.purchase_price) }}
            </template>

          </v-data-table>

          <product-variant-supplier-dialog
            v-model="variantSupplierDialog"
            :attributes="variantSupplier"
            :errors="variantSupplierErrors"
            :suppliers="suppliers"
            @save="saveVariantSupplier()"
          ></product-variant-supplier-dialog>



        </v-card>
      </v-col>

    </v-row>


  </div>
</template>

<script>
import { inject, ref, watch } from 'vue'
import ProductVariantDialog from '@/components/ProductVariantDialog'
import ProductVariantSupplierDialog from '@/components/ProductVariantSupplierDialog'
import { formatCurrency } from '@/utils'
import { useRouter, useRoute } from 'vue-router/composables'

export default {
  components: {
    ProductVariantDialog,
    ProductVariantSupplierDialog,
  },
  setup() {
    const api = inject('api')
    const { user } = api
    const router = useRouter()
    const route = useRoute()

    const variantModel = api.model('product-variants/:id', {
      params: {
        expand: 'product,size,primary_color,secondary_color,tertiary_color,quaternary_color',
      },
    })

    const { attributes: variant, errors: variantErrors, oldAttributes: oldVariant } = variantModel
    const variantDialog = ref(false)

    function saveVariant() {
      variantModel.save().then(() => {
        variantDialog.value = false
      })
    }

    function deleteVariant() {
      variantModel.delete().then(() => {
        router.push({
          name: 'variants',
        })
      })
    }

    function showVariantDialog() {
      variantModel.reset()
      variantDialog.value = true
    }

    const { items: categories } = api.collection('categories').all()
    const { items: brands } = api.collection('brands').all()
    const { items: sizes } = api.collection('sizes').all()
    const { items: suppliers } = api.collection('suppliers').all()

    const variantSupplierCollection = api.collection('product-variant-suppliers', {
      params: {
        expand: 'supplier',
      },
      filter: {
        product_variant_id: 'eq',
        query: 'query:sku',
      },
    })

    const { items: variantSuppliers, filter, options, totalCount, isSearching } = variantSupplierCollection

    const variantSupplierHeaders = [
      { text: 'Supplier', value: 'supplier.name', },
      { text: 'Article Number', value: 'sku' },
      user.can('show:sensitive') ? { text: 'Purchase Price', value: 'purchase_price' } : null,
    ].filter(header => header !== null)

    const variantSupplierSearch = variantSupplierCollection.debounceSearch(300)

    const variantSupplierModel = api.model('product-variant-suppliers/:product_variant_id,:supplier_id')

    const { attributes: variantSupplier, errors: variantSupplierErrors } = variantSupplierModel
    const variantSupplierDialog = ref(false)

    const { items: colors } = api.collection('colors').all()

    function showNewVariantSupplierDialog() {
      variantSupplierModel.clear().setAttribute('product_variant_id', variantModel.getAttribute('id'))
      variantSupplierDialog.value = true
    }

    function saveVariantSupplier() {
      variantSupplierModel.save().then(() => {
        variantSupplierCollection.search()
        variantSupplierDialog.value = false
      })
    }

    function showVariantSupplierDialog(item) {
      if (user.can('product-variant-supplier.update')) {
        variantSupplierModel.clear().populate(item)
        variantSupplierDialog.value = true
      }
    }

    // done
    watch(() => route, ({ params }) => {
      if (params.id) {
        variantModel.find(params.id).catch(() => {
          router.push({
            name: 'products',
          })
        })
        variantSupplierCollection.setFilter('product_variant_id', params.id).search()
      }
    }, { immediate: true })

    return {
      user: api.user,
      variant,
      variantErrors,
      oldVariant,

      colors,
      categories,
      brands,
      sizes,
      suppliers,

      variantDialog,
      showVariantDialog,
      saveVariant,
      deleteVariant,

      variantSuppliers,
      filter,
      options,
      totalCount,
      isSearching,
      variantSupplierHeaders,
      variantSupplier,
      variantSupplierErrors,
      variantSupplierDialog,
      variantSupplierSearch,

      showNewVariantSupplierDialog,
      showVariantSupplierDialog,
      saveVariantSupplier,

      formatCurrency,
    }

  },
}
</script>

<style scoped>

:deep(.v-data-table.clickable table tbody tr:not(.v-data-table__empty-wrapper):hover) {
  cursor: pointer;
}

dl.table dt, dl.table dd {
  padding-top: 2px;
  padding-bottom: 2px;
}


</style>